/*! modernizr 3.13.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-customelements-setclasses !*/
!(function (n, e, s) {
  function a(n, e) {
    return typeof n === e;
  }
  var t = [],
    i = {
      _version: '3.13.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function (n, e) {
        var s = this;
        setTimeout(function () {
          e(s[n]);
        }, 0);
      },
      addTest: function (n, e, s) {
        t.push({ name: n, fn: e, options: s });
      },
      addAsyncTest: function (n) {
        t.push({ name: null, fn: n });
      }
    },
    Modernizr = function () {};
  (Modernizr.prototype = i), (Modernizr = new Modernizr());
  var l = [],
    f = s.documentElement,
    r = 'svg' === f.nodeName.toLowerCase();
  Modernizr.addTest('customelements', 'customElements' in e),
    (function () {
      var n, e, s, o, i, f, r;
      for (var c in t)
        if (t.hasOwnProperty(c)) {
          if (
            ((n = []),
            (e = t[c]),
            e.name &&
              (n.push(e.name.toLowerCase()),
              e.options && e.options.aliases && e.options.aliases.length))
          )
            for (s = 0; s < e.options.aliases.length; s++)
              n.push(e.options.aliases[s].toLowerCase());
          for (
            o = a(e.fn, 'function') ? e.fn() : e.fn, i = 0;
            i < n.length;
            i++
          )
            (f = n[i]),
              (r = f.split('.')),
              1 === r.length
                ? (Modernizr[r[0]] = o)
                : ((Modernizr[r[0]] &&
                    (!Modernizr[r[0]] || Modernizr[r[0]] instanceof Boolean)) ||
                    (Modernizr[r[0]] = new Boolean(Modernizr[r[0]])),
                  (Modernizr[r[0]][r[1]] = o)),
              l.push((o ? '' : 'no-') + r.join('-'));
        }
    })(),
    (function (n) {
      var e = f.className,
        s = Modernizr._config.classPrefix || '';
      if ((r && (e = e.baseVal), Modernizr._config.enableJSClass)) {
        var o = new RegExp('(^|\\s)' + s + 'no-js(\\s|$)');
        e = e.replace(o, '$1' + s + 'js$2');
      }
      Modernizr._config.enableClasses &&
        (n.length > 0 && (e += ' ' + s + n.join(' ' + s)),
        r ? (f.className.baseVal = e) : (f.className = e));
    })(l),
    delete i.addTest,
    delete i.addAsyncTest;
  for (var c = 0; c < Modernizr._q.length; c++) Modernizr._q[c]();
  n.Modernizr = Modernizr;
})(window, window, document);
